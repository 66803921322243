import React from 'react';
import { theme, MarginContainer } from 'style';

type KeyValue = {
  key: string;
  value: string;
};

type Props = {
  label: string;
  options: KeyValue[];
  selected: string;
  onChange: (newSelected: string) => void;
};

const Dropdown = ({ label, options, selected, onChange }: Props) => (
  <MarginContainer my={theme.spacing.m}>
    <label htmlFor={`checkbox-${label}`} style={{ marginRight: '10px' }}>
      {label}
    </label>
    <select
      id={`dropdown-${label}`}
      value={selected}
      onChange={e => onChange(e.target.value)}
    >
      {options.map(({ key, value }) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  </MarginContainer>
);

export default Dropdown;
