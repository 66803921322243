import React from 'react';
import { Order } from 'types';
import { MarginContainer, FlexContainer, theme } from 'style';
import { getOrderHeaderInfo } from 'utils';
import BackChevron from './BackChevron';

type Props = {
  order: Order;
};

const OrderPageHeader = ({ order }: Props) => {
  const { orderNumberText, fromToText } = getOrderHeaderInfo(order);

  return (
    <MarginContainer>
      <FlexContainer justifyContent="start" alignItems="center">
        <BackChevron />
        <h1>{orderNumberText}</h1>
      </FlexContainer>
      <MarginContainer mt={theme.spacing.xl}>
        <p>{fromToText}</p>
      </MarginContainer>
    </MarginContainer>
  );
};

export default OrderPageHeader;
