export const { REACT_APP_ENV = 'development' } = process.env;

export const rattId = {
  production: 'a1358b25-05f1-40af-92e9-18113475387e',
  test: 'oRIVsNwDQfifK9rvO1mHlR',
  development: '20f8de81-8a5a-4c9b-a904-7e4c5639d22f'
}[REACT_APP_ENV];

export const baseUrl =
  {
    production: 'https://api.dagens.farm',
    test: 'https://api-test.dagens.farm'
  }[REACT_APP_ENV] || 'http://localhost:3000/api';

export const airbrakeConfig = {
  projectId: 469685,
  projectKey: '5301ce2a3714a70fb48b2a3de2f8a817',
  environment: REACT_APP_ENV
};
