import React from 'react';
import LogisticsActionLinkPickupDetails from 'components/LogisticsActionLinkPickupDetails';
import LogisticsActionLinkDeliveryDetails from 'components/LogisticsActionLinkDeliveryDetails';
import { LogisticsActionInfo } from 'types';

export type Props = {
  infoTexts: LogisticsActionInfo;
};

const LogisticsActionLinkContent = ({ infoTexts }: Props) => {
  const { type } = infoTexts;

  return type === 'pickup' ? (
    <LogisticsActionLinkPickupDetails infoTexts={infoTexts} />
  ) : (
    <LogisticsActionLinkDeliveryDetails infoTexts={infoTexts} />
  );
};

export default LogisticsActionLinkContent;
