import React from 'react';
import { postLogin } from 'api';
import { MarginContainer, theme } from 'style';
import InputWithLabel from 'components/InputWithLabel';
import LoginContainer from '../components/LoginContainer';

const LoginPage = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loginError, setLoginError] = React.useState('');

  const submit = async (
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ): Promise<any> => {
    e.preventDefault();

    if (!email || !password) return;

    try {
      await postLogin({ email, password });
      window.location.href = '/';
    } catch (error) {
      setLoginError(
        'Something went wrong! Please check that password and email is correct.'
      );
    }
  };
  return (
    <div>
      <h1>Log in</h1>
      <form onSubmit={submit}>
        <MarginContainer mt={80}>
          <InputWithLabel
            headerText="Email address"
            type="email"
            autoComplete="email"
            placeholder="Please enter email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <InputWithLabel
            headerText="Password"
            type="password"
            placeholder="Please enter password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <LoginContainer email={email} password={password} submit={submit} />
        </MarginContainer>
      </form>
      {loginError && <p style={{ color: theme.palette.error }}>{loginError}</p>}
    </div>
  );
};

export default LoginPage;
