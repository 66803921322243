import React from 'react';

const LogisticsActionLinkPickupDetails = ({ infoTexts }: any) => {
  const {
    fromName,
    fromDeadline,
    contactInfo,
    toName,
    bookingNumberText,
    statusText,
    withText,
    trackingNumberText
  } = infoTexts;
  return (
    <>
      <h3 className="bold">{bookingNumberText}</h3>
      <p className="collapse secondary">From</p>
      <p className="collapse bold">{fromName}</p>
      <p className="collapse">{fromDeadline}</p>
      <p className="collapse">{contactInfo}</p>
      <p className="secondary">To {toName}</p>
      <p>{withText}</p>
      <p>{trackingNumberText}</p>
      <p className="bold">{statusText}</p>
    </>
  );
};

export default LogisticsActionLinkPickupDetails;
