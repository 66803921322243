import React from 'react';
import { Order } from 'types';
import { getPickedUpOrders } from 'utils';
import DistributionOrderLink from './DistributionOrderLink';

type Props = {
  orders: Order[];
  legIds: string[];
};

const DistributionOrderList = ({ orders, legIds }: Props) => {
  const pickedUpOrders = getPickedUpOrders(orders, legIds);

  return (
    <>
      <p>Orders:</p>
      {pickedUpOrders.map(pickedUpOrder => (
        <DistributionOrderLink
          key={pickedUpOrder._id}
          pickedUpOrder={pickedUpOrder}
          legIds={legIds}
        />
      ))}
    </>
  );
};

export default DistributionOrderList;
