import React from 'react';
import { Consumer } from 'types';
import { theme, MarginContainer } from 'style';
import { getConsumerContactInfo } from 'utils';

type Props = {
  consumer: Consumer;
};

const ConsumerContactInfo = ({ consumer }: Props) => {
  const {
    name,
    phone,
    deliveryAddress,
    deliveryInfo,
    deliveryWindow
  } = getConsumerContactInfo(consumer);
  return (
    <>
      <MarginContainer mb={theme.spacing.l} mt={theme.spacing.xl}>
        <p className="collapse">{name}</p>
        <p className="collapse">{phone}</p>
      </MarginContainer>
      <MarginContainer mb={theme.spacing.l}>
        <p className="collapse">{deliveryAddress}</p>
        <p className="collapse">{deliveryWindow}</p>
        <p className="collapse">{deliveryInfo}</p>
      </MarginContainer>
    </>
  );
};

export default ConsumerContactInfo;
