import React from 'react';
import { DeliveryRouteStep, LogisticsAction, Order } from 'types';
import { theme, FlexContainer, MarginContainer } from 'style';
import { formatDateWithTime, createPayload } from 'utils';
import useRequestClickHandler from 'hooks/useRequestClickHandler';
import ActionButton from './ActionButton';
import OrderLink from './OrderLink';

type Props = {
  order: Order;
  routeStep: DeliveryRouteStep;
  isActionComplete: boolean;
  logisticsAction: LogisticsAction;
};

const PickupOrderListElement = ({
  order,
  routeStep,
  isActionComplete,
  logisticsAction
}: Props) => {
  const { legIds } = logisticsAction;
  const buttonText = isActionComplete
    ? `Picked up ${formatDateWithTime(routeStep.pickupTime)}`
    : 'Mark as picked up';
  const [pendingRequest, onClickHandler] = useRequestClickHandler(
    createPayload([order], legIds, 'pickup', false)
  );

  const [pendingRequestRevert, onRevertClickHandler] = useRequestClickHandler(
    createPayload([order], legIds, 'pickup', true)
  );
  return (
    <>
      <MarginContainer mt={theme.spacing.l} mb={theme.spacing.xl}>
        <OrderLink order={order} legIds={legIds} />
        <FlexContainer justifyContent="initial">
          <ActionButton
            buttonText={buttonText}
            disabled={pendingRequest || isActionComplete}
            onClick={onClickHandler}
          />
          {isActionComplete && (
            <MarginContainer mx={20}>
              <ActionButton
                buttonText="Undo"
                padding="8px 10px"
                disabled={pendingRequestRevert}
                onClick={onRevertClickHandler}
              />
            </MarginContainer>
          )}
        </FlexContainer>
      </MarginContainer>
    </>
  );
};

export default PickupOrderListElement;
