import styled, { css } from 'styled-components';

export const theme = {
  palette: {
    white: '#ffffff',
    black: '#000000',
    secondary: '#767676',
    grey: '#cacaca',
    lightGrey: '#dedede',
    imagePlaceholder: '#f0f0f0',
    messages: '#ccbfa3',
    new: '#ddccff',
    noteworthy: '#cca88f',
    warning: '#ffac59',
    availability: '#dee5cf',
    available: '#9bc193',
    season: '#77b36b',
    error: '#ff4713',
    frozen: '#0000FF'
  },
  hover: { new: '#e7dbff' },
  borderRadius: { base: 5, small: 3 },
  spacing: {
    xxs: 4,
    xs: 8,
    s: 16,
    m: 24,
    l: 32,
    xl: 48,
    jumbo: 96
  },
  typography: {
    fontSize: {
      displayTitle: 40,
      primaryHeading: 34,
      secondaryHeading: 28,
      leadText: 20,
      paragraph: 16,
      inputText: 16,
      buttonText: 14,
      paragraphSmall: 14,
      xs: 12
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    }
  }
};

export type Color = keyof typeof theme['palette'];
export type ColorValue =
  | typeof theme['palette'][Color]
  | typeof theme['hover'][keyof typeof theme['hover']];

type MarginProps = {
  mx?: number;
  my?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
};

export const margins = css<MarginProps>`
  ${({ mx }) =>
    mx !== undefined && `margin-left: ${mx}px; margin-right: ${mx}px;`}
  ${({ my }) =>
    my !== undefined && `margin-top: ${my}px; margin-bottom: ${my}px;`}
  margin-top: ${({ mt }) => (mt ? `${mt}px` : undefined)};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : undefined)};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : undefined)};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : undefined)};
`;

export const MarginContainer = styled.div`
  ${margins}
`;

export const OrderLineContainer = styled.div`
  padding: 5px;
  border: 1px solid black;
  margin-bottom: 10px;
`;

type FlexContainerProps = {
  justifyContent?: string;
  alignItems?: string;
};

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent = 'space-between' }) => justifyContent};
  align-items: ${({ alignItems = 'stretch' }) => alignItems};
`;

export type ButtonProps = {
  backgroundColor?: string;
  color?: string;
  padding?: string;
  width?: string;
} & MarginProps;

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  background-color: ${({ backgroundColor = theme.palette.black }) =>
    backgroundColor};
  color: ${({ color = theme.palette.white }) => color};
  padding: ${({ padding = '8px 36px' }) => padding};
  border: 2px solid
    ${({ backgroundColor = theme.palette.black }) => backgroundColor};
  border-radius: ${theme.borderRadius.base}px;
  ${margins}
  width: ${({ width = 'initial' }) => width}
`;

type ChevronProps = {
  up?: boolean;
};

export const Chevron = styled.img<ChevronProps>`
  transform: ${({ up = false }) => (up ? 'rotate(180deg)' : 'initial')};
`;
