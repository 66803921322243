import React from 'react';
import { Order } from 'types';
import OrderLink from './OrderLink';

type Props = {
  orders: Order[];
  legIds: string[];
};

const DeliveryOrderList = ({ orders, legIds }: Props) => (
  <>
    {orders.map(order => (
      <OrderLink key={order._id} order={order} legIds={legIds} />
    ))}
  </>
);

export default DeliveryOrderList;
