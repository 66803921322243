import React from 'react';
import { useHistory } from 'react-router-dom';
import chevron from 'assets/chevron.svg';

const BackChevron = () => {
  const history = useHistory();
  return (
    <button
      type="button"
      style={{
        border: 'none',
        background: 'transparent',
        padding: '5px 5px 5px 0',
        marginRight: '2px',
        cursor: 'pointer'
      }}
      onClick={() => {
        if (history.length > 1) {
          history.goBack();
        } else {
          history.replace('/');
        }
      }}
    >
      <img
        src={chevron}
        style={{ verticalAlign: 'middle', transform: 'rotate(90deg)' }}
        alt="Back"
      />
    </button>
  );
};

export default BackChevron;
