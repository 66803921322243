import React from 'react';
import { theme, MarginContainer } from 'style';

const EmptyState = () => (
  <MarginContainer my={theme.spacing.l}>
    <p>Hmmm... Nothing to see here. Try to adjust filters or go back :)</p>
  </MarginContainer>
);

export default EmptyState;
