import React from 'react';
import { OrderLine } from 'types';
import { OrderLineContainer } from 'style';
import { getCountAndPriceAndUnit } from '@dagensmat/core';

type Props = {
  orderLine: OrderLine;
};

const OrderLinesListElement = ({ orderLine }: Props) => {
  const { product } = orderLine;
  const { count, unit } = getCountAndPriceAndUnit(orderLine);
  const qtyAndUnit = `${count} ${unit}`;
  return (
    <OrderLineContainer>
      <span>{product.name}</span>
      <span style={{ float: 'right' }}>{qtyAndUnit}</span>
    </OrderLineContainer>
  );
};

export default OrderLinesListElement;
