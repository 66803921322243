import { sortBy } from 'lodash';
import moment from 'moment';
import {
  DeliveryRouteStep,
  Order,
  LogisticsActionType,
  LogisticsAction,
  LogisticsActionInfo,
  LogisticsLegWithOrders,
  ConsumersWithOrders,
  Consumer,
  Payload
} from 'types';

/** Dates etc. */

const DATE_SLUG_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT = 'dddd DD MMMM';
const DATE_WITH_TIME_FORMAT = 'DD/MM HH:mm';

export const slugifyDate = (date?: string) =>
  moment(date).format(DATE_SLUG_FORMAT);

export const formatDate = (date?: string) => moment(date).format(DATE_FORMAT);

export const formatDateWithTime = (date?: string) =>
  moment(date).format(DATE_WITH_TIME_FORMAT);

export const dateIsPassed = (date: string): boolean => {
  const endOfDate = moment(date).endOf('day');
  return endOfDate.isBefore(moment(new Date()));
};

/** Groupers, counters etc. */

export const getRouteStepFromOrder = (order: Order, legIds: string[]) => {
  const { deliveryRoute } = order;
  const routeStep = deliveryRoute.find(({ logisticsLeg }) =>
    legIds.includes(logisticsLeg._id)
  );
  return routeStep!;
};

export const groupOrdersByLegs = (logisticsAction: LogisticsAction) => {
  const { legIds, orders } = logisticsAction;

  const groups = orders.reduce((acc, order) => {
    const leg = getRouteStepFromOrder(order, legIds).logisticsLeg;
    const key = leg._id;
    if (acc[key]) {
      acc[key].orders.push(order);
      return acc;
    }
    acc[key] = {
      ...leg,
      orders: [order]
    };
    return acc;
  }, {} as Record<string, LogisticsLegWithOrders>);
  return Object.values(groups);
};

export const sortOrdersByConsumerNameOnAction = (
  logisticsAction: LogisticsAction
): LogisticsAction => {
  const { orders } = logisticsAction;
  const sortedOrdersByConsumerName = sortBy(orders, 'consumer.name');
  return { ...logisticsAction, orders: sortedOrdersByConsumerName };
};

export const groupOrdersByConsumers = (logisticsAction: LogisticsAction) => {
  const { orders, legIds } = logisticsAction;
  const consumerOrders = orders.reduce((acc, order) => {
    const { consumer } = order;
    const { _id: consumerId } = consumer;

    if (acc[consumerId]) {
      acc[consumerId].orders.push(order);
      return acc;
    }
    acc[consumerId] = {
      legIds,
      consumer,
      orders: [order]
    };
    return acc;
  }, {} as Record<string, ConsumersWithOrders>);
  return Object.values(consumerOrders);
};

export const groupLogisticsActionsByDate = (
  logisticsActions: LogisticsAction[]
) => {
  const groups = logisticsActions.reduce((acc, logisticsAction) => {
    const key = slugifyDate(logisticsAction.date);

    if (acc[key]) {
      acc[key].logisticsActions.push(logisticsAction);
      return acc;
    }
    acc[key] = {
      date: logisticsAction.date,
      logisticsActions: [logisticsAction]
    };
    return acc;
  }, {} as Record<string, { date: string; logisticsActions: LogisticsAction[] }>);

  return Object.values(groups).sort((a, b) => a.date.localeCompare(b.date));
};

export const checkActionCompleteOnRouteStep = (
  routeStep: DeliveryRouteStep,
  logisticsActionType: LogisticsActionType
) =>
  logisticsActionType === 'pickup'
    ? Boolean(routeStep.pickupTime)
    : Boolean(routeStep.deliveryTime);

export const getPickedUpOrders = (orders: Order[], legIds: string[]) =>
  orders.filter(order => {
    const routeStep = getRouteStepFromOrder(order, legIds);
    return checkActionCompleteOnRouteStep(routeStep, 'pickup');
  });

export const getPickedUpAndNotDeliveredOrders = (
  orders: Order[],
  legIds: string[]
) =>
  orders.filter(order => {
    const routeStep = getRouteStepFromOrder(order, legIds);
    return (
      checkActionCompleteOnRouteStep(routeStep, 'pickup') &&
      !checkActionCompleteOnRouteStep(routeStep, 'delivery')
    );
  });

export const getLastDeliveryTimeStamp = (leg: LogisticsLegWithOrders) => {
  const { orders, _id } = leg;
  const routeSteps = orders.map(order => getRouteStepFromOrder(order, [_id]));

  const routeStepsWithDeliveryTime = routeSteps.filter(({ deliveryTime }) =>
    Boolean(deliveryTime)
  );

  if (routeStepsWithDeliveryTime.length === 0) {
    return '';
  }
  const sortDeliveryTimes = routeStepsWithDeliveryTime.sort((a, b) =>
    b.deliveryTime!.localeCompare(a.deliveryTime!)
  );
  return formatDateWithTime(sortDeliveryTimes[0].deliveryTime);
};

export const getLastDeliveryTimeStampFromOrdersAndLegIds = (
  orders: Order[],
  legIds: string[]
) => {
  const routeSteps = orders.map(order => getRouteStepFromOrder(order, legIds));

  const routeStepsWithDeliveryTime = routeSteps.filter(({ deliveryTime }) =>
    Boolean(deliveryTime)
  );

  if (routeStepsWithDeliveryTime.length === 0) {
    return '';
  }
  const sortDeliveryTimes = routeStepsWithDeliveryTime.sort((a, b) =>
    b.deliveryTime!.localeCompare(a.deliveryTime!)
  );
  return formatDateWithTime(sortDeliveryTimes[0].deliveryTime);
};

export const countActionCompleteOnLegOrders = (
  orders: Order[],
  legIds: string[],
  logisticsActionType: LogisticsActionType
) =>
  orders.filter(order => {
    const routeStep = getRouteStepFromOrder(order, legIds);
    return checkActionCompleteOnRouteStep(routeStep, logisticsActionType);
  }).length;

export const filterPickedUpOrders = (
  legsWithOrders: LogisticsLegWithOrders[]
) =>
  legsWithOrders.filter(({ orders, _id }) =>
    orders.find(order => {
      const routeStep = getRouteStepFromOrder(order, [_id]);
      return checkActionCompleteOnRouteStep(routeStep, 'pickup');
    })
  );

export const filterOutPastActions = (logisticsActions: LogisticsAction[]) =>
  logisticsActions.filter(
    logisticsAction => !dateIsPassed(logisticsAction.date)
  );

export const filterOutCompletedActions = (
  logisticsActions: LogisticsAction[]
) =>
  logisticsActions.filter(logisticsAction => {
    const { orders, legIds } = logisticsAction;
    return (
      countActionCompleteOnLegOrders(orders, legIds, logisticsAction.type) <
      orders.length
    );
  });

export const filterPartnerActions = (
  logisticsActions: LogisticsAction[],
  partnerId: string
) =>
  logisticsActions.filter(
    logisticsAction => logisticsAction.partner._id === partnerId
  );

export const getUniqueLogisticsPartners = (
  logisticsActions: LogisticsAction[]
) =>
  Object.values(
    logisticsActions.reduce((acc, { partner }) => {
      acc[partner._id] = partner;
      return acc;
    }, {} as Record<string, any>)
  );

/** Other stuff */

export const capitalizeFirstLetter = (s: string) =>
  s[0].toUpperCase() + s.slice(1);

const formatToText = (to: string, deadline?: string): string =>
  deadline ? `${to}, before ${deadline}` : `${to}`;

const formatFromText = (from: string, deadline?: string): string =>
  deadline ? `${from}, after ${deadline}` : `${from}`;

const formatContactInfoText = (contactPerson: string, phone: string): string =>
  `Contact info: ${contactPerson} ${phone}`;

export const getLogisticsActionInfo = (
  logisticsAction: LogisticsAction
): LogisticsActionInfo => {
  const {
    bookingNumbers,
    orders,
    legIds,
    type,
    date,
    partner,
    to,
    from,
    legType
  } = logisticsAction;

  const pickedUpCount = countActionCompleteOnLegOrders(
    orders,
    legIds,
    'pickup'
  );

  const deliveredCount = countActionCompleteOnLegOrders(
    orders,
    legIds,
    'delivery'
  );

  const completedCount = countActionCompleteOnLegOrders(orders, legIds, type);
  const bookingNumbersString = bookingNumbers.join(', ');
  const bookingNumberText = `${capitalizeFirstLetter(
    type
  )} ${bookingNumbersString}`;
  const withText = `With: ${partner.name}`;

  const trackingNumbersString = orders
    .filter(order => order.bringOrderNumber)
    .map(({ bringOrderNumber }) => bringOrderNumber)
    .join(', ');

  const mutualFields = {
    bookingNumberText,
    date,
    withText,
    completedCount,
    type
  };

  switch (type) {
    case 'pickup': {
      return {
        ...mutualFields,
        totalCount: orders.length,
        fromName: formatFromText(from.name, from.deadline),
        contactInfo: formatContactInfoText(from.contactPerson, from.phone),
        toName: `${to.name}`,
        fromToText: `${from.name} → ${to.name}`,
        statusText: `Picked up: ${pickedUpCount}/${orders.length}`,
        trackingNumberText:
          trackingNumbersString && legType === 'transport'
            ? `Tracking number(s): ${trackingNumbersString}`
            : ''
      };
    }
    case 'delivery': {
      return {
        ...mutualFields,
        totalCount: pickedUpCount,
        fromToText: `To ${to.name}`,
        toName: formatToText(to.name, to.deadline),
        contactInfo: formatContactInfoText(to.contactPerson, to.phone),
        statusText: `Delivered: ${deliveredCount}/${pickedUpCount}`
      };
    }
    default: {
      return {
        ...mutualFields,
        totalCount: pickedUpCount,
        toName: `${to.name}`,
        fromToText: `To ${to.name}`,
        statusText: `Delivered: ${deliveredCount}/${pickedUpCount}`
      };
    }
  }
};

export const getOrderHeaderInfo = (order: Order) => {
  const { orderNumberString, consumer, producer, deliveryDate } = order;

  return {
    orderNumberText: `Order ${orderNumberString}`,
    fromToText: `${producer.name} -> ${consumer.name}, ${formatDate(
      deliveryDate
    )}`
  };
};

export const getConsumerContactInfo = (consumer: Consumer) => {
  const {
    name,
    contactPerson,
    phone,
    address,
    deliveryInfo,
    deliveryWindow
  } = consumer;
  return {
    name: `${name}, ${contactPerson}`,
    phone: `${phone}`,
    deliveryAddress: `${address}`,
    deliveryInfo: deliveryInfo ? `${deliveryInfo}` : '',
    deliveryWindow: `${deliveryWindow.start} - ${deliveryWindow.end}`
  };
};

const isNrOfUnitsDeliveredNotZero = (orderLine: any): boolean => {
  if (
    !Object.prototype.hasOwnProperty.call(
      orderLine,
      'nrOfPricedUnitsDelivered'
    ) &&
    !Object.prototype.hasOwnProperty.call(
      orderLine,
      'nrOfOrderedUnitsDelivered'
    )
  ) {
    return true;
  }
  if (
    orderLine.nrOfPricedUnitsDelivered &&
    orderLine.nrOfPricedUnitsDelivered !== 0
  ) {
    return true;
  }
  if (
    orderLine.nrOfOrderedUnitsDelivered &&
    orderLine.nrOfOrderedUnitsDelivered !== 0
  ) {
    return true;
  }
  return false;
};
export const getFrozenTagOnOrder = (order: Order): string => {
  const { orderLines } = order;

  const includesFrozen = orderLines.some(
    ol =>
      ol.product.temperatureZone === 'FROZEN' && isNrOfUnitsDeliveredNotZero(ol)
  );
  return includesFrozen ? 'FROZEN' : '';
};

export const getRouteStepStatusText = (
  order: Order,
  legIds: string[]
): string => {
  const routeStep = getRouteStepFromOrder(order, legIds);
  if (routeStep.deliveryTime) {
    return `Delivered ${formatDateWithTime(routeStep.deliveryTime)}`;
  }

  if (routeStep.pickupTime) {
    return `Picked up ${formatDateWithTime(routeStep.pickupTime)}`;
  }
  return '';
};

export const createPayload = (
  orders: Order[],
  legIds: string[],
  type: LogisticsActionType,
  unsetStatus: Boolean
): Payload => {
  switch (type) {
    case 'pickup':
      return {
        orders: orders.map(order => {
          const routeStep = getRouteStepFromOrder(order, legIds);
          return {
            orderId: order._id,
            routeStepKey: routeStep._key
          };
        }),
        status: 'pickupTime',
        unsetStatus
      };
    default: {
      const pickedUpAndNotDeliveredOrders = getPickedUpAndNotDeliveredOrders(
        orders,
        legIds
      );
      return {
        orders: pickedUpAndNotDeliveredOrders.map(order => {
          const routeStep = getRouteStepFromOrder(order, legIds);
          return {
            orderId: order._id,
            routeStepKey: routeStep._key
          };
        }),
        status: 'deliveryTime',
        unsetStatus
      };
    }
  }
};
