import ActionButton from 'components/ActionButton';
import React from 'react';
import { FlexContainer, theme } from 'style';

type Props = {
  email: string;
  password: string;
  submit: (event: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => void;
};

const LoginContainer = ({ email, password, submit }: Props) => (
  <>
    <ActionButton
      padding="12px 15px"
      buttonText="Log in"
      disabled={!(email && password)}
      onClick={submit}
      type="submit"
      width="-webkit-fill-available"
    />
    <FlexContainer justifyContent="center">
      <a
        style={{
          color: theme.palette.secondary
        }}
        href="https://platform.dagens.farm/reset-password"
        target="_blank"
        rel="noopener noreferrer"
      >
        Forgot password?
      </a>
    </FlexContainer>
  </>
);

export default LoginContainer;
