import React, { useState } from 'react';
import { LogisticsAction } from 'types';
import { getRouteStepFromOrder, checkActionCompleteOnRouteStep } from 'utils';
import Checkboxes from './Checkboxes';
import PickupOrderListElement from './PickupOrderListElement';

type Props = {
  logisticsAction: LogisticsAction;
};

const HIDE_PICKED_UP = 'HIDE_PICKED_UP';

const FILTER_OPTIONS = [
  {
    key: HIDE_PICKED_UP,
    value: 'Hide already picked up'
  }
];

const PickupOrderList = ({ logisticsAction }: Props) => {
  const [filters, setFilters] = useState<string[]>([HIDE_PICKED_UP]);
  const { orders } = logisticsAction;

  return (
    <>
      <h2>Orders</h2>
      <Checkboxes
        options={FILTER_OPTIONS}
        selected={filters}
        onChange={setFilters}
      />
      {orders.map(order => {
        const { legIds, type } = logisticsAction;
        const routeStep = getRouteStepFromOrder(order, legIds);
        const actionComplete = checkActionCompleteOnRouteStep(routeStep, type);

        return (
          <div key={order._id}>
            {filters.includes(HIDE_PICKED_UP) && actionComplete ? (
              <></>
            ) : (
              <PickupOrderListElement
                order={order}
                routeStep={routeStep}
                isActionComplete={actionComplete}
                logisticsAction={logisticsAction}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default PickupOrderList;
