import React, { Component } from 'react';
import { Notifier } from '@airbrake/browser';

interface ErrorBoundaryState {
  hasError: boolean;
}
interface ErrorBoundaryProps {
  airbrakeConfig: {
    projectId: number;
    projectKey: string;
    environment: string;
  };
  children: React.ReactChild;
}
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps & ErrorBoundaryState) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const { airbrakeConfig } = this.props;
    const { projectId, projectKey, environment } = airbrakeConfig;
    const airbrake = new Notifier({
      projectId,
      projectKey,
      environment
    });
    this.setState({ hasError: true });

    airbrake.notify({
      error,
      params: { info }
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
