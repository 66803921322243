import { atom, selector, selectorFamily } from 'recoil';
import { uniqBy } from 'lodash';
import { Order, LogisticsLeg, DeliveryRouteStep, LogisticsAction } from 'types';
import { getOrders } from 'api';

export const fetchOrdersState = selector({
  key: 'fetchOrdersState',
  get: async () => getOrders()
});

export const ordersState = atom({
  key: 'ordersState',
  default: fetchOrdersState
});

export const updateOrdersState = selector({
  key: 'updateOrdersState',
  get: ({ get }) => get(ordersState),
  set: ({ get, set }, updatedOrders: any) => {
    const orders: Order[] = get(ordersState);

    const newOrdersState = orders.map(
      order =>
        (updatedOrders as Order[]).find(
          ({ _id }: { _id: string }) => _id === order._id
        ) || order
    );
    set(ordersState, newOrdersState);
  }
});

export const getOrdersWithLegId = selectorFamily({
  key: 'getOrdersWithLegId',
  get: (legId: string) => ({ get }) => {
    const orders: Order[] = get(ordersState);
    return orders.filter(({ deliveryRoute }) =>
      deliveryRoute.find(({ logisticsLeg }) => logisticsLeg._id === legId)
    );
  }
});

export const getLogisticsActions = selector({
  key: 'getLogisticsActions',
  get: ({ get }): LogisticsAction[] => {
    const orders: Order[] = get(ordersState);
    const deliveryRoutes = orders.map(
      ({ deliveryRoute = [] }) => deliveryRoute
    );
    const allLogisticsLegs = ([] as DeliveryRouteStep[])
      .concat(...deliveryRoutes)
      .map(({ logisticsLeg }) => logisticsLeg);

    const legs: LogisticsLeg[] = uniqBy(allLogisticsLegs, '_id');

    const allPickups: LogisticsAction[] = legs.map(leg => ({
      key: leg._id,
      legIds: [leg._id],
      type: 'pickup',
      legType: leg.type,
      bookingNumbers: [leg.bookingNumber],
      date: leg.startDate,
      orders: get(getOrdersWithLegId(leg._id)),
      from: leg.from,
      to: leg.to,
      partner: leg.partner
    }));

    const allDeliveries = legs.reduce((acc, leg) => {
      const key = `${leg.to._id}${leg.partner._id}${leg.endDate}`;
      const ordersWithLegId = get(getOrdersWithLegId(leg._id));

      if (acc[key]) {
        acc[key].legIds.push(leg._id);
        acc[key].bookingNumbers.push(leg.bookingNumber);
        acc[key].orders = [...acc[key].orders, ...ordersWithLegId];

        return acc;
      }
      acc[key] = {
        key,
        legIds: [leg._id],
        legType: leg.type,
        type: leg.to._type === 'distributionArea' ? 'distribution' : 'delivery',
        bookingNumbers: [leg.bookingNumber],
        date: leg.endDate,
        orders: ordersWithLegId,
        to: leg.to,
        partner: leg.partner
      };
      return acc;
    }, {} as Record<string, LogisticsAction>);

    return [...allPickups, ...Object.values(allDeliveries)];
  }
});

export const getLogisticsAction = selectorFamily({
  key: 'getLogisticsAction',
  get: (actionKey: string) => ({ get }) =>
    get(getLogisticsActions).find(({ key }) => key === actionKey)
});

export const getOrder = selectorFamily({
  key: 'getOrder',
  get: (orderId: string) => ({ get }) =>
    get(fetchOrdersState).find(({ _id }: { _id: string }) => _id === orderId)
});
