import React from 'react';
import { useSetRecoilState } from 'recoil';
import { updateOrdersState } from 'recoil/orders';
import { setDeliveryStatus } from 'api';
import { Payload } from 'types';

const useRequestClickHandler = (
  payload: Payload
): [boolean, () => Promise<void>] => {
  const [pendingRequest, setPendingRequest] = React.useState(false);
  const setOrdersState = useSetRecoilState(updateOrdersState);
  const onClickHandler = async () => {
    setPendingRequest(true);
    const response = await setDeliveryStatus(payload);
    setPendingRequest(false);
    setOrdersState(response);
  };
  return [pendingRequest, onClickHandler];
};

export default useRequestClickHandler;
