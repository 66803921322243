import styled from 'styled-components';
import { margins } from 'style';

export const TRANSITION_DURATION = 320;

const Input = styled.input`
  padding: ${({ theme }) => theme.spacing.xxs + theme.spacing.xs}px;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing.s}px;
  margin-top: ${({ theme }) => theme.spacing.xs}px;
  outline: none;

  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.palette.grey};

  background: ${({ theme }) => theme.palette.white};
  font-size: ${({ theme }) => `${theme.typography.fontSize.inputText}px`};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.palette.secondary};
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.secondary};
  }

  &:disabled:hover {
    border-color: ${({ theme }) => theme.palette.grey};
  }

  &:focus:enabled {
    color: ${({ theme }) => theme.palette.black};
    border-color: ${({ theme }) => theme.palette.black};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  transition: border-color ${TRANSITION_DURATION}ms ease-in-out;

  ${margins};
`;

export default Input;
