import React from 'react';
import { theme, MarginContainer } from 'style';

type Props = {
  message: string;
};

const EmptyState = ({ message }: Props) => (
  <MarginContainer my={theme.spacing.l}>
    <p>{message}</p>
  </MarginContainer>
);

export default EmptyState;
