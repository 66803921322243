import React from 'react';
import { useSetRecoilState } from 'recoil';
import { authState } from 'recoil/auth';
import { Button } from 'style';
import { getLogout } from 'api';

const LogoutButton = () => {
  const setAuthState = useSetRecoilState(authState);
  return (
    <Button
      onClick={async () => {
        await getLogout();
        setAuthState(null);
      }}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
