import React from 'react';
import './App.css';
import { BrowserRouter as Switch, Route } from 'react-router-dom';
import PrivateRoute from 'components/utils/PrivateRoute';
import ActionPage from 'pages/ActionPage';
import LandingPage from 'pages/LandingPage';
import OrderPage from 'pages/OrderPage';
import LoginPage from 'pages/LoginPage';

const App = () => (
  <Switch>
    <PrivateRoute exact path="/" component={LandingPage} />
    <PrivateRoute exact path="/order/:orderId" component={OrderPage} />
    <PrivateRoute
      exact
      path="/logistics-action/:actionKey"
      component={ActionPage}
    />
    <Route exact path="/login" component={LoginPage} />
  </Switch>
);
export default App;
