import React from 'react';
import { Link } from 'react-router-dom';
import { FlexContainer, theme } from 'style';
import { Order } from 'types';
import { getRouteStepStatusText, getFrozenTagOnOrder } from 'utils';

type Props = {
  pickedUpOrder: Order;
  legIds: string[];
};

const DistributionOrderLink = ({ pickedUpOrder, legIds }: Props) => {
  const { _id, orderNumberString } = pickedUpOrder;
  return (
    <Link to={`/order/${_id}`}>
      <FlexContainer justifyContent="normal">
        <p>{orderNumberString}</p>
        <p style={{ color: theme.palette.frozen, marginLeft: '6px' }}>
          {getFrozenTagOnOrder(pickedUpOrder)}
        </p>
        <p style={{ marginLeft: '6px' }}>{'->'}</p>
      </FlexContainer>
      <p className="secondary">
        {getRouteStepStatusText(pickedUpOrder, legIds)}
      </p>
    </Link>
  );
};

export default DistributionOrderLink;
