import React from 'react';
import { Order } from 'types';
import OrderLinesListElement from './OrderLinesListElement';

type Props = {
  order: Order;
};

const OrderLinesList = ({ order }: Props) => {
  const { orderLines } = order;
  return (
    <>
      {orderLines.map(orderLine => (
        <OrderLinesListElement key={orderLine._key} orderLine={orderLine} />
      ))}
    </>
  );
};

export default OrderLinesList;
