import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { theme } from 'style';
import LoadingState from 'components/utils/LoadingState';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary';
import { airbrakeConfig } from 'config';
import App from './App';

/** Refresh page every hour. Set for drivers not quitting the Move app... */
const REFRESH_INTERVAL = 60 * 60 * 1000;
setInterval(() => {
  window.location.reload();
}, REFRESH_INTERVAL);

ReactDOM.render(
  <div className="app-container">
    <RecoilRoot>
      <ErrorBoundary airbrakeConfig={airbrakeConfig}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<LoadingState message="Loading data..." />}>
            <App />
          </Suspense>
        </ThemeProvider>
      </ErrorBoundary>
    </RecoilRoot>
  </div>,
  document.getElementById('root')
);
