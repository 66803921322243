import React from 'react';
import { Link } from 'react-router-dom';
import { FlexContainer, theme } from 'style';
import { Order } from 'types';
import { getRouteStepStatusText, getFrozenTagOnOrder } from 'utils';

type Props = {
  order: Order;
  legIds: string[];
};

const OrderLink = ({ order, legIds }: Props) => {
  const { _id, orderNumberString, producer, consumer } = order;

  const lastStatusTimeStamp = getRouteStepStatusText(order, legIds);
  return (
    <Link to={`/order/${_id}`}>
      <FlexContainer justifyContent="normal">
        <p>{orderNumberString}</p>
        <p style={{ color: theme.palette.frozen, marginLeft: '6px' }}>
          {getFrozenTagOnOrder(order)}
        </p>
      </FlexContainer>
      <p>{`${consumer.name} → ${producer.name}`}</p>
      <p className="secondary">{lastStatusTimeStamp}&nbsp;</p>
    </Link>
  );
};

export default OrderLink;
