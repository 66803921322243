import React from 'react';
import { xor } from 'lodash';
import { theme, MarginContainer } from 'style';

type KeyValue = {
  key: string;
  value: string;
};

type Props = {
  options: KeyValue[];
  selected: string[];
  onChange: (newSelected: string[]) => void;
};

const Checkboxes = ({ options, selected, onChange }: Props) => (
  <MarginContainer my={theme.spacing.m}>
    {options.map(({ key, value }) => (
      <label
        key={key}
        htmlFor={`checkbox-${key}`}
        style={{ marginRight: '10px' }}
      >
        <input
          id={`checkbox-${key}`}
          type="checkbox"
          checked={selected.includes(key)}
          onChange={() => onChange(xor(selected, [key]))}
        />
        {value}
      </label>
    ))}
  </MarginContainer>
);

export default Checkboxes;
