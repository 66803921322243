import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from 'recoil/auth';

const PrivateRoute = ({ exact, path, component }: any) => {
  const loggedIn = useRecoilValue(authState);

  if (loggedIn) {
    return <Route exact={exact} path={path} component={component} />;
  }

  return <Redirect to="/login" />;
};

export default PrivateRoute;
