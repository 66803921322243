import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getLogisticsAction } from 'recoil/orders';
import { LogisticsAction } from 'types';
import {
  getLogisticsActionInfo,
  sortOrdersByConsumerNameOnAction
} from 'utils';
import ActionPageHeader from 'components/ActionPageHeader';
import PickupOrderList from 'components/PickupOrderList';
import DeliveryList from 'components/DeliveryList';
import DistributionPageContent from 'components/DistributionPageContent';

type Props = {
  logisticsAction: LogisticsAction;
};

const renderActionPageContent = (logisticsAction: LogisticsAction) => {
  switch (logisticsAction.type) {
    case 'pickup':
      return <PickupOrderList logisticsAction={logisticsAction} />;
    case 'delivery':
      return <DeliveryList logisticsAction={logisticsAction} />;
    case 'distribution':
      return <DistributionPageContent logisticsAction={logisticsAction} />;
    default:
      return null;
  }
};

const ActionPage: React.FC<Props> = () => {
  const params: { [key: string]: string } = useParams();
  const { actionKey } = params;
  const logisticsAction = useRecoilValue(getLogisticsAction(actionKey));

  if (!logisticsAction) return null;

  const logisticsActionWithSortedOrders = sortOrdersByConsumerNameOnAction(
    logisticsAction
  );
  const infoTexts = getLogisticsActionInfo(logisticsAction);
  return (
    <>
      <ActionPageHeader infoTexts={infoTexts} />
      {renderActionPageContent(logisticsActionWithSortedOrders)}
    </>
  );
};

export default ActionPage;
