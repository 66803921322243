import React from 'react';
import Input from './Input.style';
import { theme } from '../style';

type Props = {
  headerText: string;
  type: string;
  autoComplete?: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputWithLabel = ({
  headerText,
  type,
  autoComplete,
  placeholder,
  value,
  onChange
}: Props) => (
  <>
    <label
      htmlFor={headerText}
      style={{
        fontSize: theme.typography.fontSize.paragraph,
        fontWeight: theme.typography.fontWeight.semiBold
      }}
    >
      {headerText}
    </label>
    <Input
      id={headerText}
      type={type}
      autoComplete={autoComplete}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </>
);

export default InputWithLabel;
