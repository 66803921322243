import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getOrder } from 'recoil/orders';
import OrderPageHeader from 'components/OrderPageHeader';
import ConsumerContactInfo from 'components/ConsumerContactInfo';
import OrderLinesList from 'components/OrderLinesList';

const OrderPage = () => {
  const params: { [key: string]: string } = useParams();
  const { orderId } = params;
  const order = useRecoilValue(getOrder(orderId));
  const { consumer } = order;
  return (
    <>
      <OrderPageHeader order={order} />
      <ConsumerContactInfo consumer={consumer} />
      <OrderLinesList order={order} />
    </>
  );
};

export default OrderPage;
