import React from 'react';
import { LogisticsActionInfo } from 'types';
import { theme, MarginContainer, FlexContainer } from 'style';
import { formatDate } from 'utils';
import BackChevron from './BackChevron';

type Props = {
  infoTexts: LogisticsActionInfo;
};

const ActionPageHeader = ({ infoTexts }: Props) => {
  const {
    bookingNumberText,
    date,
    fromToText,
    withText,
    statusText,
    trackingNumberText
  } = infoTexts;
  return (
    <MarginContainer mb={theme.spacing.xl}>
      <FlexContainer justifyContent="start" alignItems="center">
        <BackChevron />
        <h1>{bookingNumberText}</h1>
      </FlexContainer>
      <p>{fromToText}</p>
      <p>{formatDate(date)}</p>
      <p>{withText}</p>
      <p>{trackingNumberText}</p>
      <p className="bold">{statusText}</p>
    </MarginContainer>
  );
};

export default ActionPageHeader;
