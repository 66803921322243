import { Payload, Order } from 'types';
import { baseUrl } from './config';

class AuthenticationError extends Error {
  statusCode: number;

  constructor(...params: any) {
    super(...params);

    this.name = 'AuthenticationError';
    this.statusCode = 401;
  }
}

const handleResponse = async (response: Response) => {
  const { status } = response;

  if (status >= 200 && status < 400) return response.json();

  if (status === 401) {
    throw new AuthenticationError();
  }

  throw new Error('Some request error');
};

const defaultOptions = {
  method: 'GET',
  credentials: 'include' as RequestCredentials,
  headers: {
    Accept: 'application/json',
    From: 'move-dagens'
  }
};

const getPostOptions = (payload: Payload) => ({
  ...defaultOptions,
  method: 'POST',
  body: JSON.stringify(payload),
  headers: {
    ...defaultOptions.headers,
    'Content-Type': 'application/json'
  }
});

export const getIdentity = async () => {
  const response = await fetch(
    `${baseUrl}/auth?roleTypesToInclude[]=logisticsPartner`,
    defaultOptions
  );

  return handleResponse(response);
};

export const postLogin = async (payload: any) => {
  const response = await fetch(
    `${baseUrl}/auth/login`,
    getPostOptions(payload)
  );

  return handleResponse(response);
};

export const getLogout = async () => {
  const response = await fetch(`${baseUrl}/auth/logout`, defaultOptions);

  return handleResponse(response);
};

export const getOrders = async () => {
  const response = await fetch(
    `${baseUrl}/order/withLogistics`,
    defaultOptions
  );

  return handleResponse(response);
};

export const setDeliveryStatus = async (payload: Payload): Promise<Order[]> => {
  const response = await fetch(
    `${baseUrl}/order/deliveryStatuses`,
    getPostOptions(payload)
  );

  return handleResponse(response);
};
