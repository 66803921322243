import React from 'react';
import { Link } from 'react-router-dom';
import { LogisticsAction } from 'types';
import { getLogisticsActionInfo } from 'utils';
import LogisticsActionLinkContainer from './LogisticsActionLinkContainer';
import LogisticsActionLinkContent from './LogisticsActionLinkContent';

type Props = {
  logisticsAction: LogisticsAction;
};

const LogisticsActionLink = ({ logisticsAction }: Props) => {
  const info = getLogisticsActionInfo(logisticsAction);
  return (
    <Link to={`/logistics-action/${logisticsAction.key}`}>
      <LogisticsActionLinkContainer
        completedOrderCount={info.completedCount}
        orderCount={info.totalCount}
        date={info.date}
      >
        <LogisticsActionLinkContent infoTexts={info} />
      </LogisticsActionLinkContainer>
    </Link>
  );
};

export default LogisticsActionLink;
