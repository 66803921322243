import React from 'react';
import { theme, MarginContainer, Button } from 'style';

type Props = {
  buttonText: string;
  disabled: boolean;
  // eslint-disable-next-line
  padding?: string;
  right?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
  width?: string;
};

const ActionButton = ({
  buttonText,
  disabled,
  padding,
  right = false,
  onClick,
  type = 'button',
  width
}: Props) => (
  <MarginContainer
    my={theme.spacing.m}
    style={{ textAlign: right ? 'right' : 'initial' }}
  >
    <Button
      width={width}
      padding={padding}
      disabled={disabled}
      backgroundColor={disabled ? theme.palette.grey : theme.palette.black}
      color={disabled ? theme.palette.black : theme.palette.white}
      onClick={onClick}
      type={type}
    >
      {buttonText}
    </Button>
  </MarginContainer>
);

export default ActionButton;
