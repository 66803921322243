import React from 'react';
import { LogisticsLegWithOrders } from 'types';
import { theme, MarginContainer, FlexContainer, Chevron } from 'style';
import chevron from 'assets/chevron.svg';
import {
  createPayload,
  getLastDeliveryTimeStamp,
  getPickedUpOrders
} from 'utils';
import ActionButton from './ActionButton';
import DeliveryOrderList from './DeliveryOrderList';
import useRequestClickHandler from '../hooks/useRequestClickHandler';

type Props = {
  leg: LogisticsLegWithOrders;
  isActionComplete: boolean;
};

const DeliveryListElement = ({ leg, isActionComplete }: Props) => {
  const [showOrderList, setShowOrderList] = React.useState(false);
  const { orders, _id } = leg;
  const [pendingRequest, onClickHandler] = useRequestClickHandler(
    createPayload(orders, [_id], 'delivery', false)
  );
  const pickedUpOrders = getPickedUpOrders(orders, [_id]);
  const buttonText = isActionComplete
    ? `Delivered ${getLastDeliveryTimeStamp(leg)}`
    : 'Mark all pick ups as delivered';
  return (
    <MarginContainer mt={theme.spacing.l} mb={theme.spacing.xl}>
      <FlexContainer
        role="button"
        onClick={() => {
          setShowOrderList(!showOrderList);
        }}
      >
        <h2>{leg.from.name}</h2>
        <Chevron src={chevron} alt="Chevron" up={showOrderList} />
      </FlexContainer>
      <ActionButton
        buttonText={buttonText}
        disabled={pendingRequest || isActionComplete}
        onClick={onClickHandler}
      />
      {showOrderList && (
        <DeliveryOrderList orders={pickedUpOrders} legIds={[leg._id]} />
      )}
    </MarginContainer>
  );
};

export default DeliveryListElement;
