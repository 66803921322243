import React from 'react';
import chevron from 'assets/chevron.svg';
import { theme, MarginContainer, FlexContainer, Chevron } from 'style';
import { ConsumersWithOrders } from 'types';
import {
  getLastDeliveryTimeStampFromOrdersAndLegIds,
  getPickedUpOrders,
  getPickedUpAndNotDeliveredOrders,
  createPayload
} from 'utils';
import useRequestClickHandler from 'hooks/useRequestClickHandler';
import DistributionOrderList from './DistributionOrderList';
import ConsumerContactInfo from './ConsumerContactInfo';
import ActionButton from './ActionButton';

type Props = {
  consumerWithPickedUpOrders: ConsumersWithOrders;
  isActionComplete: boolean;
};

const DistributionListElement = ({
  consumerWithPickedUpOrders,
  isActionComplete
}: Props) => {
  const [showOrderList, setShowOrderList] = React.useState(false);
  const { consumer, orders, legIds } = consumerWithPickedUpOrders;
  const pickedUpOrders = getPickedUpOrders(orders, legIds);
  const pickedUpAndNotDeliveredToConsumer = getPickedUpAndNotDeliveredOrders(
    orders,
    legIds
  );

  const [pendingRequest, onClickHandler] = useRequestClickHandler(
    createPayload(orders, legIds, 'distribution', false)
  );

  const buttonText = isActionComplete
    ? `Delivered ${getLastDeliveryTimeStampFromOrdersAndLegIds(orders, legIds)}`
    : `Mark ${pickedUpAndNotDeliveredToConsumer.length}/${orders.length} orders as delivered`;
  return (
    <MarginContainer mt={theme.spacing.l} mb={theme.spacing.xl}>
      <FlexContainer
        role="button"
        onClick={() => {
          setShowOrderList(!showOrderList);
        }}
      >
        <h2>{consumer.name}</h2>
        <Chevron src={chevron} alt="Chevron" up={showOrderList} />
      </FlexContainer>
      <p className="secondary">
        {pickedUpOrders.length}/{orders.length} orders picked up
      </p>
      {showOrderList && (
        <>
          <ConsumerContactInfo consumer={consumer} />{' '}
          <DistributionOrderList
            key={consumer._id}
            orders={orders}
            legIds={legIds}
          />
        </>
      )}
      <ActionButton
        buttonText={buttonText}
        disabled={pendingRequest || isActionComplete}
        onClick={onClickHandler}
      />
    </MarginContainer>
  );
};

export default DistributionListElement;
