import React, { useState } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { sortBy } from 'lodash';
import LogisticsActionList from 'components/LogisticsActionList';
import Checkboxes from 'components/Checkboxes';
import Dropdown from 'components/Dropdown';
import LogoutButton from 'components/LogoutButton';
import EmptyState from 'components/utils/EmptyState';
import { getLogisticsActions } from 'recoil/orders';
import { getRoles } from 'recoil/auth';
import { Button, FlexContainer, theme } from 'style';
import {
  groupLogisticsActionsByDate,
  filterOutPastActions,
  filterOutCompletedActions,
  getUniqueLogisticsPartners,
  filterPartnerActions
} from 'utils';

const DEFAULT_DAYS_TO_SHOW = 2;
const HIDE_PRODUCER_LEGS = 'HIDE_PRODUCER_LEGS';
const HIDE_PAST_DAYS = 'HIDE_PAST_DAYS';
const HIDE_COMPLETED = 'HIDE_COMPLETED';

const FILTER_OPTIONS = [
  {
    key: HIDE_PAST_DAYS,
    value: 'Hide past days'
  },
  {
    key: HIDE_COMPLETED,
    value: 'Hide completed'
  }
];

const defaultFilters = [HIDE_PRODUCER_LEGS, HIDE_PAST_DAYS, HIDE_COMPLETED];

const useLocalStorage = (key: string) => {
  const [filters, setFilters] = React.useState<string[]>(
    JSON.parse(localStorage.getItem(key) || '0') || defaultFilters
  );

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(filters));
  }, [filters]);

  return [filters, setFilters] as const;
};

export const logisticsPartnerFilterState = atom({
  key: 'logisticsPartnerFilterState',
  default: ''
});

const LandingPage = () => {
  const [numberOfDaysShownInList, setNumberOfDaysShownInList] = useState(
    DEFAULT_DAYS_TO_SHOW
  );

  const roleIds = useRecoilValue(getRoles).map(
    ({ _id }: { _id: string }) => _id
  );

  const allLogisticsActions = useRecoilValue(
    getLogisticsActions
  ).filter(action => roleIds.includes(action.partner._id));

  const [filters, setFilters] = useLocalStorage('filters');
  const [logisticsPartnerFilter, setLogisticsPartnerFilter] = useRecoilState(
    logisticsPartnerFilterState
  );

  const logisticsPartnerFilterOptions = [
    { key: '', value: 'All partners' },
    ...sortBy(
      getUniqueLogisticsPartners(allLogisticsActions).map(({ _id, name }) => ({
        key: _id,
        value: name
      })),
      'value'
    )
  ];

  let logisticsActionsToRender = allLogisticsActions;

  if (logisticsPartnerFilter) {
    logisticsActionsToRender = filterPartnerActions(
      allLogisticsActions,
      logisticsPartnerFilter
    );
  }

  if (filters.includes(HIDE_PAST_DAYS)) {
    logisticsActionsToRender = filterOutPastActions(logisticsActionsToRender);
  }

  if (filters.includes(HIDE_COMPLETED)) {
    logisticsActionsToRender = filterOutCompletedActions(
      logisticsActionsToRender
    );
  }

  const logisticsActionsByDate = groupLogisticsActionsByDate(
    logisticsActionsToRender
  );

  const handleChangeNumberOfDays = (amount: number) => {
    if (numberOfDaysShownInList + amount > logisticsActionsByDate.length) {
      setNumberOfDaysShownInList(logisticsActionsByDate.length);
    }
    setNumberOfDaysShownInList(numberOfDaysShownInList + amount);
  };

  const logisticsActionsToShow = logisticsActionsByDate.slice(
    0,
    numberOfDaysShownInList
  );
  const isMoreDays: boolean =
    logisticsActionsByDate.length > 0 &&
    logisticsActionsToShow.length !== logisticsActionsByDate.length;

  return (
    <div>
      <FlexContainer alignItems="center">
        <h1>All pickups &amp; deliveries</h1>
        <div>
          <LogoutButton />
        </div>
      </FlexContainer>
      <Dropdown
        label="Choose logistics partner"
        options={logisticsPartnerFilterOptions}
        selected={logisticsPartnerFilter}
        onChange={setLogisticsPartnerFilter}
      />
      <Checkboxes
        options={FILTER_OPTIONS}
        selected={filters}
        onChange={setFilters}
      />
      {logisticsActionsToShow.map(({ logisticsActions, date }) => (
        <LogisticsActionList
          key={date}
          logisticsActions={logisticsActions}
          date={date}
        />
      ))}
      {logisticsActionsByDate.length === 0 && <EmptyState />}
      {isMoreDays && (
        <FlexContainer
          justifyContent="center"
          style={{ marginBottom: theme.spacing.m }}
        >
          <Button
            onClick={() => {
              handleChangeNumberOfDays(2);
            }}
          >
            See more days
          </Button>
        </FlexContainer>
      )}
    </div>
  );
};

export default LandingPage;
