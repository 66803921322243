import React, { useState } from 'react';
import { LogisticsAction } from 'types';
import {
  filterPickedUpOrders,
  getPickedUpAndNotDeliveredOrders,
  groupOrdersByLegs
} from 'utils';
import Checkboxes from './Checkboxes';
import DeliveryListElement from './DeliveryListElement';

type Props = {
  logisticsAction: LogisticsAction;
};

const HIDE_DELIVERED = 'HIDE_DELIVERED';

const FILTER_OPTIONS = [
  {
    key: HIDE_DELIVERED,
    value: 'Hide already delivered'
  }
];

const DeliveryList = ({ logisticsAction }: Props) => {
  const [filters, setFilters] = useState<string[]>([HIDE_DELIVERED]);
  const legsWithOrders = groupOrdersByLegs(logisticsAction);
  const legsWithPickedUpOrders = filterPickedUpOrders(legsWithOrders);

  return (
    <>
      <Checkboxes
        options={FILTER_OPTIONS}
        selected={filters}
        onChange={setFilters}
      />
      {legsWithPickedUpOrders.map(leg => {
        const { orders, _id } = leg;
        const pickedUpAndNotDeliveredOrders = getPickedUpAndNotDeliveredOrders(
          orders,
          [_id]
        );
        const actionComplete = pickedUpAndNotDeliveredOrders.length === 0;
        return (
          <div key={leg._id}>
            {filters.includes(HIDE_DELIVERED) && actionComplete ? (
              <></>
            ) : (
              <DeliveryListElement
                leg={leg}
                isActionComplete={actionComplete}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default DeliveryList;
