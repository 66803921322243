import React, { useState } from 'react';
import { LogisticsAction } from 'types';
import {
  checkActionCompleteOnRouteStep,
  getPickedUpAndNotDeliveredOrders,
  getRouteStepFromOrder,
  groupOrdersByConsumers
} from 'utils';
import Checkboxes from './Checkboxes';
import DistributionListElement from './DistributionListElement';

type Props = {
  logisticsAction: LogisticsAction;
};

const HIDE_DELIVERED = 'HIDE_DELIVERED';

const FILTER_OPTIONS = [
  {
    key: HIDE_DELIVERED,
    value: 'Hide already delivered'
  }
];

const DistributionPageContent = ({ logisticsAction }: Props) => {
  const [filters, setFilters] = useState<string[]>([HIDE_DELIVERED]);
  const consumersWithOrders = groupOrdersByConsumers(logisticsAction);
  const consumersWithPickedUpOrders = consumersWithOrders.filter(
    ({ orders, legIds }) => {
      const found = orders.find(order => {
        const routeStep = getRouteStepFromOrder(order, legIds);
        return checkActionCompleteOnRouteStep(routeStep, 'pickup');
      });
      return Boolean(found);
    }
  );
  return (
    <>
      <Checkboxes
        options={FILTER_OPTIONS}
        selected={filters}
        onChange={setFilters}
      />
      {consumersWithPickedUpOrders.map(consumerWithPickedUpOrders => {
        const { orders, legIds } = consumerWithPickedUpOrders;
        const pickedUpAndNotDeliveredToConsumer = getPickedUpAndNotDeliveredOrders(
          orders,
          legIds
        );

        const actionComplete = pickedUpAndNotDeliveredToConsumer.length === 0;
        return (
          <div key={consumerWithPickedUpOrders.consumer._id}>
            {filters.includes(HIDE_DELIVERED) && actionComplete ? (
              <></>
            ) : (
              <DistributionListElement
                key={consumerWithPickedUpOrders.consumer._id}
                consumerWithPickedUpOrders={consumerWithPickedUpOrders}
                isActionComplete={actionComplete}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default DistributionPageContent;
