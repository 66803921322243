import { atom, selector } from 'recoil';
import { getIdentity } from 'api';

const getUserObject = async () => {
  try {
    const user = await getIdentity();
    return user;
  } catch (error) {
    return null;
  }
};

export const fetchAuthState = selector({
  key: 'fetchAuthState',
  get: async () => getUserObject()
});

export const authState = atom({
  key: 'authState',
  default: fetchAuthState
});

export const getRoles = selector({
  key: 'getRoles',
  get: ({ get }) => {
    const auth = get(authState);
    return auth.roles;
  }
});
