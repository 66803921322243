import React from 'react';
import { LogisticsAction } from 'types';
import { theme, MarginContainer } from 'style';
import { formatDate } from 'utils';
import LogisticsActionLink from './LogisticsActionLink';

type Props = {
  logisticsActions: LogisticsAction[];
  date: string;
};

const LogisticsActionList = ({ logisticsActions, date }: Props) => (
  <MarginContainer mt={theme.spacing.jumbo} mb={theme.spacing.xl}>
    <h2>{formatDate(date)}</h2>
    {logisticsActions.map(logisticsAction => (
      <LogisticsActionLink
        key={logisticsAction.key}
        logisticsAction={logisticsAction}
      />
    ))}
  </MarginContainer>
);

export default LogisticsActionList;
